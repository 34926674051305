<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg-10">
        <div class="container">
          <h4 class="mb-3">Enrollment for {{ enrollment.studentName }} in {{ enrollment.className }}</h4>
          <router-link :to="{ name: 'admin_class_enrollments', params: { id: enrollment.classId } }">&lt; Back to enrollments</router-link>
          <form @submit.prevent="saveEnrollment()">
            <div class="row">
              <div class="col">
                <div class="my-3">
                  <label class="form-label" for="status">Status</label>
                  <select class="form-select" name="status" id="status" v-model="enrollment.status" required>
                    <option value="unpaid">Unpaid</option>
                    <option value="enrolled">Enrolled</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="my-3">
                  <label class="form-label" for="tuition">Tuition</label>
                  <input class="form-control" type="number" step="0.1" min="0" name="tuition" id="tuition" v-model="enrollment.tuition" required>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button type="submit" class="btn btn-primary" :disabled="waiting">Save</button>
              <button type="button" class="btn btn-danger" @click="deleteEnrollment()" :disabled="waiting">Delete</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AdminNav from '@/components/AdminNav.vue'
import Enrollment from '@/types/enrollment'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      waiting: false,
      enrollment: {
        studentName: '',
        className: '',
        status: ''
      } as Enrollment
    }
  },
  async mounted () {
    const enrollmentId = this.$route.params.id
    if (!Array.isArray(enrollmentId)) {
      const response = await this.$api.getEnrollment(parseInt(enrollmentId))
      if (response.enrollment) {
        this.enrollment = response.enrollment
      }
    }
  },
  methods: {
    async saveEnrollment () {
      this.waiting = true
      // save enrollment
      const response = await this.$api.updateEnrollment(this.enrollment)
      if (response.enrollment) {
        this.enrollment = response.enrollment
        this.waiting = false
      }
    },
    async deleteEnrollment () {
      if (!confirm('Are you sure you want to delete this enrollment?')) {
        return
      }
      this.waiting = true
      // delete enrollment
      const response = await this.$api.deleteEnrollment(this.enrollment.id)
      if (response.success) {
        this.$router.replace({ name: 'admin_class_enrollments', params: { id: this.enrollment.classId } })
      }
    }
  }
})
</script>
