
import AdminNav from '@/components/AdminNav.vue'
import Enrollment from '@/types/enrollment'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      waiting: false,
      enrollment: {
        studentName: '',
        className: '',
        status: ''
      } as Enrollment
    }
  },
  async mounted () {
    const enrollmentId = this.$route.params.id
    if (!Array.isArray(enrollmentId)) {
      const response = await this.$api.getEnrollment(parseInt(enrollmentId))
      if (response.enrollment) {
        this.enrollment = response.enrollment
      }
    }
  },
  methods: {
    async saveEnrollment () {
      this.waiting = true
      // save enrollment
      const response = await this.$api.updateEnrollment(this.enrollment)
      if (response.enrollment) {
        this.enrollment = response.enrollment
        this.waiting = false
      }
    },
    async deleteEnrollment () {
      if (!confirm('Are you sure you want to delete this enrollment?')) {
        return
      }
      this.waiting = true
      // delete enrollment
      const response = await this.$api.deleteEnrollment(this.enrollment.id)
      if (response.success) {
        this.$router.replace({ name: 'admin_class_enrollments', params: { id: this.enrollment.classId } })
      }
    }
  }
})
